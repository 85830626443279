<template>
    <div class="section" key="tatvorwurf">
        <div class="section-title">
            <div class="author"><img src="@/assets/images/rabier.png"></div>
            <h3>Jeder zweite verschickte Bußgeldbescheid ist fehlerhaft! Ist es Ihrer auch?</h3>
            <p>Jetzt <a href="#">kostenlos</a> und <a href="#">unverbindlich</a> prüfen</p>
        </div>
        <div class="form-container">
            <p class="form-question">1. Was wird Ihnen vorgeworfen?</p>
            <div class="form-card">
            <input type="radio" v-model="user.accusation" name="speed-radio" value="Geschwindigkeitsverstoß" id="question-1-1">
            <label for="question-1-1" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-speedometer.svg"></span><span>Geschwindigkeitsverstoß</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.accusation" name="distance-radio" value="Abstandsverstoß" id="question-1-2">
            <label for="question-1-2" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-sedan.svg"></span><span>Abstandsverstoß</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.accusation" name="redlight-radio" value="Rotlichtverstoß" id="question-1-3">
            <label for="question-1-3" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-traffic-light.svg"></span><span>Rotlichtverstoß</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.accusation" name="mobile-drugs-parking-radio" value="Handy / Drogen / Parken" id="question-1-4">
            <label for="question-1-4" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons-beer.svg"></span><span>Handy / Drogen / Parken</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Accusation',
  computed: mapState({
    user: (state) => state.user,
    checked: (state) => state.checked
  }),
  methods: {
    ...mapMutations([
      'incrementChecked'
    ]),
  }
}
</script>